<script setup lang="ts">
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@laam/ui/base';

const accordionItems = [
	{
		title: 'What is the LAAM rewards program?\n',
		description:
			'Upon creating your account on LAAM, you automatically become a member of LAAM’s loyalty program. As loyal customers, you can check the status of your points here and discover how to unlock more points.',

		value: 'faq1',
	},
	{
		title: 'How can I earn rewards?',
		description:
			'You can earn rewards on every purchase you make. The amount of rewards varies on every product and you can check the reward amount on the details page of the product you are purchasing. Once your completed order is delivered, your rewards will be credited to you.',
		value: 'faq2',
	},
	{
		title: 'How can I redeem rewards?',
		description:
			'You can redeem your rewards during checkout. Simply select the option to apply your rewards, and they will be deducted from your total purchase amount.',
		value: 'faq3',
	},

	{
		title: 'Do rewards expire? ',
		description:
			'Yes, rewards do have an expiration date. Rewards will expire 9 months after they are earned. We recommend using your points regularly to avoid expiration',
		value: 'faq4',
	},
];
</script>
<template>
	<div class="flex flex-col">
		<h1 class="text-lg font-semibold px-3xl">FAQs</h1>
		<Accordion type="single" collapsible>
			<template v-for="(item, index) in accordionItems" :key="index">
				<AccordionItem
					:class="{ 'border-none': index === accordionItems.length - 1 }"
					:value="item.value"
				>
					<AccordionTrigger class="p-0">
						<h2 class="text-md font-medium">
							{{ item.title }}
						</h2>
					</AccordionTrigger>

					<AccordionContent class="p-3xl">
						<h3 class="text-md font-normal text-gray-600">
							{{ item.description }}
						</h3>
					</AccordionContent>
				</AccordionItem>
			</template>
		</Accordion>
	</div>
</template>
